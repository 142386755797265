import React, { Suspense } from "react";
import "./home.scss";
import Hero from "../../components/hero/Hero";
import divider from "../../assets/images/divider.svg";
import portfolio from "../../assets/images/portfolio.webp";
import Helmet from "../../components/helmet/Helmet";
import ach1 from "../../assets/images/ach1.jpg";
import ach2 from "../../assets/images/ach2.jpg";
import ach3 from "../../assets/images/ach3.jpg";
import ach4 from "../../assets/images/ach4.jpg";
import { Link } from "react-router-dom";
const HomeSection = React.lazy(() =>
  import("../../components/home-section-1/HomeSection")
);

const TestimonialSection = React.lazy(() =>
  import("../../components/testimonials-section/TestimonialSection")
);
const WorkingHours = React.lazy(() =>
  import("../../components/working-hours/WorkingHours")
);
const ServicesSection = React.lazy(() =>
  import("../../components/services-section/ServicesSection")
);
const BenefitsOfMassage = React.lazy(() =>
  import("../../components/benefits-of-massage/BenefitsOfMassage")
);

const Home = () => {
  return (
    <>
      <Helmet
        title={"Voted Best Massage in Kennesaw, Georgia | Massage Now!"}
        desc={
          "Voted best of Georgia 3 years in a row! Come see why we are the best!!! Massage Now offers the best massage spa service in Kennesaw. We are just located at 10 mins drive from Marrietta"
        }
        canonical={"https://www.massagenow-atl.com/"}
      />
      

      <Hero />

      <div className="achievements">
        <Link>
          <img src={ach1} alt="certificate-one" />
        </Link>
        <Link>
          <img src={ach2} alt="certificate-two" />
        </Link>
        <Link>
          <img src={ach3} alt="certificate-three" />
        </Link>
        <Link
          to="https://gbj.com/kennesaw/beauty-spa/massage-now"
          target="_blank"
        >
          <img src={ach4} alt="certificate-four" />
        </Link>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <HomeSection />
      </Suspense>

      {/* Divider */}
      <img loading="lazy" src={divider} alt="section-divider" />

      <Suspense fallback={<div>Loading...</div>}>
        <ServicesSection />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <BenefitsOfMassage />
      </Suspense>

      <div className="portfolio">
        <img loading="lazy" src={portfolio} alt="masssage-now-portfolio" />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <WorkingHours />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <TestimonialSection />
      </Suspense>
    </>
  );
};

export default Home;
