import React, { useRef } from "react";
import "./contact.scss";
import contactSideimg from "../../assets/images/contact-page-sideimg.webp";
import leaf from "../../assets/images/leaf.svg";
import { Link } from "react-router-dom";
import CommonSection from "../../components/common-section/CommonSection";
import call from "../../assets/images/c-call.svg";
import mail from "../../assets/images/mail.svg";
import location from "../../assets/images/location.svg";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import Helmet from "../../components/helmet/Helmet";
import MapIframe from "./MapIframe";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_on5nidn",
        "template_vmvbzy7",
        form.current,
        "bHKmzUvcOZ1zl1Pnw"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Email Sent Successfully!",
            text: "Thank You For Contacting Us! Our team will be in touch with you soon.",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
          form.current.reset();
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Try Again!",
            text: "Something went wrong, Please Try Again",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      );
  };

  return (
    <>
      <Helmet
        title={"Contact Massage Now – Top Rated Massage Center in Georgia"}
        desc={
          "Looking for the best massage experience in Georgia? At Massage Now we offer massages with trained & experienced therapists. Call us now at 678-388-0866"
        }
        canonical={"https://www.massagenow-atl.com/contact"}
      />

      <CommonSection title={"Contact Us"} />

      <div className="contact-us">
        {/* // SECTION-1 */}
        <div className="section c-section">
          <img src={leaf} alt="leaf-icon" />
          <div className="left">
            <img src={contactSideimg} alt="massage-demonstartion" />
          </div>
          <div className="right">
            <h3>Let's Connect</h3>
            <p className="desc">
              At our massage therapy business, we are committed to providing the
              best possible experience for our clients. We pride ourselves on
              offering a wide range of massage techniques and services to suit
              individual needs and preferences. Our team of professional and
              experienced massage therapists are dedicated to ensuring that
              every client feels relaxed, rejuvenated, and refreshed after every
              session. If you have any questions or would like to book an
              appointment, please do not hesitate to contact us. We are always
              happy to hear from our clients and will do our best to accommodate
              your needs. Thank you for considering our massage therapy
              services, and we look forward to serving you soon.
            </p>

            <Link to={"https://www.walmartnails.com/"} target="_blank">
              Also visit the best value nail salon in Georgia at Davi Nails
              Salon located near you in Marietta for all your manicure and
              pedicure services at the best price!
            </Link>
          </div>
        </div>

        <div className="contact-form">
          <div className="details">
            <Link
              onclick="return gtag_report_conversion('tel:+16783880866');"
              to="tel:+16783880866"
              className="call"
            >
              <img src={call} alt="call-icon" />
              <p>678 - 388 - 08661</p>
            </Link>
            <div className="mail">
              <img src={mail} alt="mail-icon" />
              <p>MassageNowSalon@Outlook.com</p>
            </div>
            <Link
              to="https://goo.gl/maps/gHo4LEsPRdKbersh8"
              target="_blank"
              className="location"
            >
              <img src={location} alt="location-icon" />
              <p>2953 N Cobb Pkwy NW Suite 1 Kennesaw, GA 30152</p>
            </Link>
          </div>

          <div className="form">
            <h3>We’d love to hear from you</h3>

            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                placeholder="Your Name*"
                name="name"
                required
              />
              <input type="number" placeholder="Phone*" name="phone" required />
              <input
                type="email"
                placeholder="Email Address*"
                name="email"
                required
              />
              <input
                type="text"
                placeholder="Your Message"
                name="message"
                required
              />
              <button type="submit">
                <span>Submit</span>
                {/* <Link>Submit</Link> */}
              </button>
            </form>
          </div>
        </div>

        {/* MAP */}
        <MapIframe />
      </div>
    </>
  );
};

export default Contact;
