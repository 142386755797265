import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Services from "../pages/services/Services";
import Legal from "../pages/legal/Legal";
import Testimonials from "../pages/testimonials/Testimonials";
import Home from "../pages/home/Home";
import MassageInsights from "../pages/massage-insights/MassageInsights";
import BlogDetailPage from "../pages/blog-detail-page/BlogDetailPage";
import Contact from "../pages/contact-us/Contact";
import GiftCertificates from "../pages/gift-certificates/GiftCertificates";
import Employment from "../pages/employment/Employment";
import Kennesaw from "../pages/services/Kennesaw";
import Atlanta from "../pages/services/Atlanta";
import Acworth from "../pages/services/Acworth";
import Marietta from "../pages/services/Marietta";
import NoMatch from "../pages/no-match/NoMatch";
import BodyMassage from "../pages/services/detail-pages/BodyMassage";
import CouplesMassage from "../pages/services/detail-pages/CouplesMassage";
import SportsMassage from "../pages/services/detail-pages/SportsMassage";
import MassageCombos from "../pages/services/detail-pages/MassageCombos";
import FourHandsMassage from "../pages/services/detail-pages/FourHandsMassage";
import ChairMassage from "../pages/services/detail-pages/ChairMassage";
import SpecialOffers from "../pages/special-offers/SpecialOffers";

const Routers = () => {
  const RedirectToServices = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/massage-services", { replace: true });
    }, [navigate]);

    return null;
  };

  const RedirectToGift = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/giftcertificates", { replace: true });
    }, [navigate]);

    return null;
  };
  const RedirectToKennesaw = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/massage-services-in-kennesaw", { replace: true });
    }, [navigate]);

    return null;
  };
  const RedirectToAcworth = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/massage-services-in-acworth", { replace: true });
    }, [navigate]);

    return null;
  };
  const RedirectToMarietta = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/massage-services-in-marietta", { replace: true });
    }, [navigate]);

    return null;
  };
  const RedirectToAtlanta = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/massage-services-in-atlanta", { replace: true });
    }, [navigate]);

    return null;
  };
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/massage-services" element={<Services />} />
      <Route path="/massage-services/body-massage" element={<BodyMassage />} />
      <Route
        path="/massage-services/couples-massage"
        element={<CouplesMassage />}
      />
      <Route
        path="/massage-services/sports-massage"
        element={<SportsMassage />}
      />
      <Route
        path="/massage-services/massage-combos"
        element={<MassageCombos />}
      />
      <Route
        path="/massage-services/four-hands-massage"
        element={<FourHandsMassage />}
      />
      <Route
        path="/massage-services/chair-massage"
        element={<ChairMassage />}
      />
      <Route path="/giftcertificates" element={<GiftCertificates />} />
      <Route path="/what-people-are-saying" element={<Testimonials />} />
      <Route path="/special-offers" element={<SpecialOffers />} />
      <Route path="/blog" element={<MassageInsights />} />
      <Route path="/employment" element={<Employment />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/blog/:id" element={<BlogDetailPage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/massage-services-in-kennesaw" element={<Kennesaw />} />
      <Route path="/massage-services-in-acworth" element={<Acworth />} />
      <Route path="/massage-services-in-marietta" element={<Marietta />} />
      <Route path="/massage-services-in-atlanta" element={<Atlanta />} />
      <Route path="*" element={<NoMatch />} />

      {/* REDIRECTION */}
      <Route path="/services" element={<RedirectToServices />} />
      <Route path="/gift-certificates" element={<RedirectToGift />} />
      <Route path="/kennesaw-massage" element={<RedirectToKennesaw />} />
      <Route path="/acworth-massage" element={<RedirectToAcworth />} />
      <Route path="/marietta-massage" element={<RedirectToMarietta />} />
      <Route path="/atlanta-massage" element={<RedirectToAtlanta />} />
      {/* REDIRECTION */}
    </Routes>
  );
};

export default Routers;
