import React from "react";
import "./special-offers.scss";
import specialOffersMain from "../../assets/images/special-offers-main.png";
import Helmet from "../../components/helmet/Helmet";
import CommonSection from "../../components/common-section/CommonSection";

const SpecialOffers = () => {
  return (
    <>
      <Helmet title={"special-offers"} canonical={"https://www.massagenow-atl.com/special-offers"} />
      <CommonSection title={"Special Offers"} />

      <div class="special-offers">
        <img className="main-img" src={specialOffersMain} alt="special-offer" />
        <h4 class="title">MASSAGE NOW - ROMANTIC COUPLES MASSAGE SPECIAL</h4>
        <div class="content">
          <ul>
            <li class="para1">$20 Off: Monday-Thursday</li>
            <li class="para2">When You Mention This Promotion</li>
          </ul>
          <button>
            <a href="tel:+16783880866">Book Now</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default SpecialOffers;
